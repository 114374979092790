let _eventBus;
let _score = 0;

const initialize = eventBus => {
  // Add score when the aim hits.
  eventBus.on ('aim:hit', () => setScore (_score + 1));
  
  // Reset the score when the game is reset.
  eventBus.on ('game:reset', () => _score = 0);
  
  _eventBus = eventBus;
}

const getScore = () => _score;

const setScore = score => {
  _score = score;
  _eventBus.emit ('score:update', _score)
}

export default {
  initialize,
  getScore,
  setScore
};