import { Arrow } from "./arrow.js";

export default class ArrowPool {
    constructor(app, texturePath, poolSize, arrowSpeed, eventBus) {
        this.app = app;
        this.pool = [];
        this.activeArrows = [];
        this.arrowSpeed = arrowSpeed;
        
        for (let i = 0; i < poolSize; i++) {
            const arrow = new Arrow(app, texturePath);
            this.pool.push(arrow);
        }

        // Register events.
        eventBus.on('game:start', this.onGameStart);
        eventBus.on('game:over', this.onGameOver);
    }

    spawn(x, y, targetId) {
        if (this.pool.length > 0) {
            const arrow = this.pool.pop();
            arrow.spawn(x, y, targetId);
            this.activeArrows.push(arrow);
            return arrow;
        }
        return null;
    }

    recycle(arrow) {
        const index = this.activeArrows.indexOf(arrow);
        if (index > -1) {
            this.activeArrows.splice(index, 1);
            arrow.recycle();
            this.pool.push(arrow);
        }
    }

    onGameStart = () => {
        while (this.activeArrows.length > 0) {
            this.recycle(this.activeArrows[0]);
        }

        this.app.ticker.add (this.updateActiveArrows);
    }

    onGameOver = () => {
        this.app.ticker.remove (this.updateActiveArrows);
    }

    updateActiveArrows = delta => {
        // Move the arrow upward over time
        for (let i = 0; i < this.activeArrows.length; i++) {
            const arrow = this.activeArrows[i];
            arrow.sprite.y -= this.arrowSpeed * delta;

            if (arrow.sprite.y > 0) continue;
            this.recycle(arrow);
        }
    }
}