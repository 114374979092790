import { Enemy } from "./enemy.js";
import { loseSfx } from "./components/sounds.js";

export default class EnemyPool {
    constructor(app, poolSize, moveSpeed, characterSpritesheet, eventBus) {
        this.stage = app.stage;
        this.screen = app.screen;
        this.ticker = app.ticker;
        this.moveSpeed = moveSpeed;
        this.pool = [];
        this.activeEnemies = [];
        this.eventBus = eventBus;
        
        for (let i = 0; i < poolSize; i++) {
            const enemy = new Enemy(characterSpritesheet, moveSpeed, eventBus);
            this.pool.push(enemy);
            this.stage.addChild (enemy);
        }

        // Register events.
        eventBus.on ('game:start', this.onGameStart);
        eventBus.on ('game:over', this.onGameOver);
        eventBus.on ('enemy:recycle', this.recycle);
    }

    spawn(x, y, isSpawningElite) {
        if (this.pool.length > 0) {
            const enemy = this.pool.pop();
            enemy.spawn(x, y, isSpawningElite ? enemy.states.WALK_HELMET : enemy.states.WALK);
            this.activeEnemies.push(enemy);
            return enemy;
        }
        return null;
    }

    getPossibleTarget () {
        // First active emeny whose state is not DEAD.
        return this.activeEnemies.find(enemy => enemy.currentState !== enemy.states.DEAD);
    }

    dealDamage(enemy) {
        const index = this.activeEnemies.indexOf(enemy);
        if (index === -1) return;
        return enemy.takeDamage();
    }

    onGameStart = () => {
        this.activeEnemies.forEach(enemy => {
            this.pool.push(enemy);
        });

        this.activeEnemies = [];
        this.ticker.add (this.updateActiveEnemies);
    }

    onGameOver = () => {
        this.ticker.remove (this.updateActiveEnemies);
    }

    updateActiveEnemies = delta => {
        for (let i = 0; i < this.activeEnemies.length; i++) {
            const enemy = this.activeEnemies[i];
            enemy.update(delta);
            // Recycle the sprite back to pool,
            // when it reached the bottom of the screen (Game Over)
            if (enemy.y < this.screen.height * 0.85) continue;
            
            loseSfx.play({ volume: 1 });
            this.eventBus.emit ('game:over');
            break;
        }
    }

    recycle = enemy => {
        const index = this.activeEnemies.indexOf(enemy);
        if (index === -1) return;
        this.activeEnemies.splice(index, 1);

        // Retrieve the sprite back to pool.
        this.pool.push(enemy);
    }
}