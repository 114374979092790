import Text from './texts/text.js';
import creditList from '../../assets/credits.js';

export default class CreditsWindow extends PIXI.Container {
    constructor(app, eventBus) {
        super();

        // Create the frame for the popup
        const frame = new PIXI.Graphics();
        frame.beginFill('black', 0.9);
        frame.drawRect(0, 0, app.screen.width, app.screen.height);
        frame.endFill();

        // Create and add credits text
        const creditsText = new Text(creditList, 16);
        creditsText.style.align = 'center';
        creditsText.x = app.screen.width / 2;
        creditsText.y = app.screen.height / 2;
        creditsText.style.wordWrap = true;
        creditsText.style.wordWrapWidth = app.screen.width * 0.96;
        creditsText.anchor.set (0.5, 0.5);
        
        // Add text to frame
        frame.addChild(creditsText); 
        this.addChild(frame);

        // Initially hide the popup
        this.visible = false;
        this.interactive = true;

        eventBus.on ('credits:show', () => this.visible = true);

        this.on ('pointerup', () => {
          this.visible = false;
          eventBus.emit ('credits:hide');
        });
    }
}