import bgmUrl from "../assets/audio/bgm.ogg";
import loseSfxUrl from "../assets/audio/lose.ogg";
import bowLoadingSfxUrl from "../assets/audio/bow-aim.ogg";
import arrowSfxUrl from "../assets/audio/arrow-shoot.ogg";
import arrowHitSfxUrl from "../assets/audio/arrow-hit.ogg";
import arrowHitMetalSfxUrl from "../assets/audio/arrow-hit-metal.ogg";
import goblinDeathSfx1Url from "../assets/audio/goblin-death-1.ogg";
import goblinDeathSfx2Url from "../assets/audio/goblin-death-2.ogg";

// BGM
export const bgm = PIXI.sound.Sound.from({
  url: bgmUrl,
  preload: true,
  loaded: function (err, sound) {
    sound.play({
      volume: 0.25,
      loop: true,
    });
  },
});

// Lose Sound
export const loseSfx = PIXI.sound.Sound.from({
  url: loseSfxUrl,
  preload: true,
});

// Bow Loading Sound
export const bowLoadingSfx = PIXI.sound.Sound.from({
  url: bowLoadingSfxUrl,
  preload: true,
});

// Arrow Sound
export const arrowSfx = PIXI.sound.Sound.from({
  url: arrowSfxUrl,
  preload: true,
});

// Hit Sound
export const arrowHitSfx = PIXI.sound.Sound.from({
  url: arrowHitSfxUrl,
  preload: true,
});

// Hit Sound
export const arrowHitMetalSfx = PIXI.sound.Sound.from({
  url: arrowHitMetalSfxUrl,
  preload: true,
});

// Goblin Death Sound 1
export const goblinDeathSfx1 = PIXI.sound.Sound.from({
  url: goblinDeathSfx1Url,
  preload: true,
});

// Goblin Death Sound 2
export const goblinDeathSfx2 = PIXI.sound.Sound.from({
  url: goblinDeathSfx2Url,
  preload: true,
});