import { resizeResponsively } from "../../../utils/font";

export default class Text extends PIXI.Text {
  constructor (text, fontSize) {
    super(text, {
      fontFamily: "Pixelify Sans",
      fontSize: resizeResponsively(fontSize),
      fill: ["#ffffff"],
      dropShadow: true,
      dropShadowAlpha: 0.5,
      dropShadowBlur: 5,
      dropShadowDistance: 3
    });
    
    this.anchor.set(0.5);
  }
}