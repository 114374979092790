import { arrowSfx } from '../sounds';

export default class ArrowSpawnHandler {
  constructor (screen, player, enemyPool, arrowPool, collisionRange, shotDeviation, eventBus) {
    this.screen = screen;
    this.player = player;
    this.enemyPool = enemyPool;
    this.arrowPool = arrowPool;
    this.collisionRange = collisionRange;
    this.shotDeviation = shotDeviation;
    this.eventBus = eventBus;

    eventBus.on ('game:start', this.onGameStart);
    eventBus.on ('game:over', this.onGameOver);
  }

  onGameStart = () => {
    this.eventBus.on ('aim:shoot', this.onShoot);
  }

  onGameOver = () => {
    this.eventBus.off ('aim:shoot', this.onShoot);
  }

  onShoot = (isHit) => {
    const targetEnemy = this.enemyPool.getPossibleTarget();
    if (!targetEnemy) return;

    let spawnPositionX = 0;
    
    // Checking pointer held time
    if (isHit)
      spawnPositionX = targetEnemy.x;
    else {
      // Randomise spawn position X
      if (Math.random() >= 0.5) {
        spawnPositionX =
          targetEnemy.x +
          this.collisionRange +
          Math.random() * this.shotDeviation;
      } else {
        spawnPositionX =
          targetEnemy.x -
          this.collisionRange -
          Math.random() * this.shotDeviation;
      }
    }

    // Clamp the value in the screen viewport
    if (spawnPositionX <= 50) {
      spawnPositionX = 50;
    }
    if (spawnPositionX >= this.screen.width - 50) {
      spawnPositionX = this.screen.width - 50;
    }

    // Spawn arrow
    this.arrowPool.spawn(spawnPositionX, this.player.y, targetEnemy.id);
    arrowSfx.play({ volume: 0.75 });
    this.eventBus.emit ('arrow:spawned', {x: spawnPositionX, y: this.player.y - 64});
  }
}