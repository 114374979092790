import Text from './text.js';
import AdController from '../../adController.js';
import { resizeResponsively } from '../../../utils/font.js';

export default class WatchAdsText extends Text {
  constructor(app, eventBus) {
    super("Watch Ads To Continue", 24);

    this.isWatchedOnce = false;
    this.eventBus = eventBus;
    this.x = app.screen.width / 2;
    this.y = app.screen.height / 2 + resizeResponsively(48);
    this.cursor = "pointer";
    this.interactive = true;
    this.visible = false;

    this.on ('pointerup', this.onShowAds);

    eventBus.on ('game:start', this.onGameStart);
    eventBus.on ('game:reset', this.onGameEnd);
    eventBus.on ('game:over', this.onGameOver);
    eventBus.on ('highestScore:set', this.onGameEnd);
  }
  
  onGameStart = () => {
    this.visible = false;
  }

  onGameEnd = () => {
    this.visible = false;
    this.isWatchedOnce = false;
  }

  onGameOver = () => {
    if (this.isWatchedOnce) return;
    this.visible = true;
  }

  onShowAds = () => {
    console.log ('Show Ads');
    AdController.show()
    .then(() => {
      this.isWatchedOnce = true;
      this.eventBus.emit ('game:start');
    })
    .catch (err => {
      console.log(err);
      this.isWatchedOnce = true;
      this.eventBus.emit ('game:start');
    });
  }
}