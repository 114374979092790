import Text from './text';

export default class CreditsText extends Text {
  constructor (eventBus) {
    super("Credits", 24);
    this.anchor.set (1, 1);
    this.cursor = "pointer";
    this.interactive = true;

    // Emit event when clicked
    this.on("pointerup", event => {
      event.stopPropagation();
      this.visible = false;
      eventBus.emit("credits:show");
    });

    eventBus.on ("credits:hide", () => this.visible = true);
  }
}