export default class Player extends PIXI.Container
{
    static index = 0;
    constructor(characterSpritesheet, enemyPool, eventBus)
    {
        super();
        this.eventBus = eventBus;
        this.enemyPool = enemyPool;

        // State machine
        this.states = {
            IDLE: 'idle',
            ATTACK: 'attack',
        };

        this.currentState = null;
        
        this.stateTransitions = {
            [this.states.IDLE]: [this.states.IDLE, this.states.ATTACK],
            [this.states.ATTACK]: [this.states.IDLE],
        };

        this.stateHandlers = {
            [this.states.IDLE]: this.handleIdle,
            [this.states.ATTACK]: this.handleAttack,
        };

        // Sprites
        const attackSprites = new PIXI.AnimatedSprite(characterSpritesheet.animations.main_char_attack);
        attackSprites.loop = false;
        
        const idleSprites = new PIXI.AnimatedSprite(characterSpritesheet.animations.main_char_idle);

        this.sprites = {
            [this.states.IDLE]: idleSprites,
            [this.states.ATTACK]: attackSprites
        };

        // Add all animations and sprite to the container.
        for (const sprite of Object.values(this.sprites)) {
            sprite.anchor.set (0.5);
            sprite.visible = false;
            if (sprite instanceof PIXI.AnimatedSprite) {
                sprite.animationSpeed = 0.1;
            }
            this.addChild(sprite);
        }

        eventBus.on ('game:start', this.onGameStart);
        eventBus.on ('game:over', this.onGameOver);
        eventBus.on ('game:reset', this.onGameReset);
        eventBus.on ('aim:start', this.onAimStart);
        eventBus.on ('arrow:spawned', this.onArrowSpawned);
    }

    setCurrentPlayState(playOrStop) {
        if (!this.currentState) return;
        
        const sprite = this.sprites[this.currentState];
        if(!(sprite instanceof PIXI.AnimatedSprite)) return;
        
        sprite.visible = playOrStop;
        if(playOrStop)
            sprite.play();
        else
            sprite.stop();
    }

    onGameReset = () => {
        this.visible = false;
        this.currentState = null;
        this.setCurrentPlayState(false);
        Object.values(this.sprites).forEach (sprite => sprite.visible = false);
    }
    
    update(delta) {
        if (!this.currentState) return;
        this.stateHandlers[this.currentState](delta);
    }

    changeState(newState) {
        if (this.currentState && !this.stateTransitions[this.currentState].includes(newState)) {
            console.error(`Invalid state transition from ${this.currentState} to ${newState}`);
            return;
        }

        if (this.currentState)
            this.sprites[this.currentState].visible = false;
        
        this.sprites[newState].visible = true;
        this.currentState = newState;
        
        this.setCurrentPlayState(true);
    }

    onGameStart = () => {
        this.visible = true;
        this.changeState(this.states.IDLE);
    }

    onGameOver = () => {
        this.currentState = null;
    }

    onAimStart = () => {
        const targetEnemy = this.enemyPool.getPossibleTarget();
        if (!targetEnemy) return;
        this.changeState(this.states.ATTACK)
    }

    onArrowSpawned = ({ x: arrowX }) => {
        this.x = arrowX;
        this.changeState(this.states.IDLE);
    };

    handleIdle = delta => {
    }

    handleAttack = delta => {
    }
}