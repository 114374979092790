const list = {
  developers: [
    "Jacky Lee",
    "Adrian Yip"
  ],
  graphicDesigners: [
    "Elvis Lai"
  ],
  royaltyFreeAudio: [
    { 
      title: "Bow Loading",
      author: "Pixabay",
    },
    { 
      title: "Bow and Arrow Shoot",
      author: "Cyberwave Orchestra from Pixabay",
    },
    { 
      title: "8 bit Laser",
      author: "Lucas NA from Pixabay",
    },
    { 
      title: "Arrow Wood Impact",
      author: "Dennis from Pixabay",
    },
    { 
      title: "Level II Short",
      author: "Vlad Krotov from Pixabay",
    },
    {
      title: "Arrow Impact on Metal",
      author: "RibhavAgrawal from Pixabay",
    },
    {
      title: "Goblin's Death 1",
      author: "Pixabay",
    },
    {
      title: "Goblin's Death 2",
      author: "Pixabay",
    },
  ]
}

export default
  "Credits:\n\n" + 
  `Developers:${list.developers.map(developer => `\n- ${developer}`).join('')}\n\n` +
  `Graphic Designers:${list.graphicDesigners.map(graphicDesigner => `\n- ${graphicDesigner}`).join('')}\n\n` +
  `Royalty Free Audio:${list.royaltyFreeAudio.map((audio, index) => `\n\n${index + 1}. ${audio.title}\n  - by ${audio.author}`).join('')}`;