export default {  
  enemy: {
    spawnTime: 60,
    moveSpeed: 2,
    collisionRange: 50,
  },

  arrow: {
    arrowSpeed: 10,
    aimThreshold: [30, 40],
    shotDeviation: 200,
  }
};