import { clamp } from '../../utils';

export default class AimingIndicatorHandler {
  constructor (aimingIndicator, [minThreshold, maxThreshold], enemyPool, eventBus) {
    this.minThreshold = minThreshold;
    this.maxThreshold = maxThreshold;
    this.enemyPool = enemyPool;    
    this.aimingIndicator = aimingIndicator;

    this.aimingIndicator.visible = false;

    eventBus.on ('aim:start', this.onAimStart);
    eventBus.on ('aim:update', this.onAimUpdate);
    eventBus.on ('aim:shoot', this.onAimShoot);
    eventBus.on ('game:reset', this.onGameOver);
  }

  onAimStart = () => {
    if (!this.enemyPool.getPossibleTarget()) return;
    this.aimingIndicator.visible = true;
  }

  onAimUpdate = (holdTimer) => {
    const targetEnemy = this.enemyPool.getPossibleTarget();
    if (!targetEnemy) return;
    // Set the position
    this.aimingIndicator.x = targetEnemy.x;
    this.aimingIndicator.y = targetEnemy.y;

    const scale = holdTimer / this.minThreshold;
    let alpha = scale;

    // Missed the target
    if (holdTimer > this.maxThreshold)
      alpha = 0.25;

    // Remap the alpha value to the scale (0 - 0.5) to (0 - 1)
    this.aimingIndicator.alpha = alpha;
    this.aimingIndicator.scale.set (clamp(scale, 0, 1)); 
  }

  onAimShoot = () => {
    this.aimingIndicator.visible = false;
  }

  onGameOver = () => {
    this.aimingIndicator.visible = false;
  }
}