import Text from './text';

export default class ScoreText extends Text {
  constructor(app, eventBus) {
    super('0', 48);
    this.x = app.screen.width / 2;
    this.y = app.screen.height * 0.02;
    this.anchor.set(0.5, 0);

    // Subscribe to events
    eventBus.on("score:update", score => {
      this.text = `${score}`;
    });

    eventBus.on("game:reset", () => {
      this.text = '0';
    });
  }
}