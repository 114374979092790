export default class EnemySpawnHandler {
  constructor (app, enemyPool, spawnTime, eventBus) {
    this.ticker = app.ticker;
    this.timer = 0;
    this.screen = app.screen;
    this.enemyPool = enemyPool;
    this.spawnTime = spawnTime;

    eventBus.on ('game:start', this.onGameStart);
    eventBus.on ('game:over', this.onGameOver);
  }

  onGameStart = () => {
    this.ticker.add (this.spawnEnemy);
  }

  onGameOver = () => {
    this.ticker.remove (this.spawnEnemy);
  }
  
  spawnEnemy = delta => {
    // Spawn enemy over time
    this.timer += delta;

    if (this.timer < this.spawnTime) return;

    this.timer = 0;
    const tenPercentWidth = this.screen.width * 0.15;
    const spawnPositionX = tenPercentWidth + Math.random() * tenPercentWidth * 4.5;

    // Spawn different type of enemy randomly
    const isSpawningElite = Math.random() < 0.1;
    this.enemyPool.spawn(spawnPositionX, 0, isSpawningElite);
  }
}