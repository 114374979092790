import Player from "../player.js";
import EnemyPool from "../enemyPool.js";
import ArrowPool from "../arrowPool.js";
import arrowUrl from "url:../assets/sprites/arrow.png";
import aimUrl from "url:../assets/sprites/UI/aim.png";

import { StartGameText, GameOverText, ScoreText, WatchAdsText } from "../components/UI/texts";
import { AimHandler, ArrowSpawnHandler, EnemySpawnHandler } from "../components/eventHandlers";
import AimingIndicatorHandler from "../components/UI/aimingIndicatorHandler";
import HitDetector from "../components/hitDetector";
import { loadSpritesheetFromJson } from "../utils/animateSpriteLoader";
import characterUrl from "url:../assets/sprites/characters-default.webp";
import characterJson from "../assets/sprites/characters-default";
import CreditsText from "../components/UI/texts/creditsText.js";

import CreditsWindow from "../components/UI/creditsWindow.js";
import ShareText from "../components/UI/texts/shareText.js";
import SkipShareText from "../components/UI/texts/skipShareText.js";

let aimHandler, arrowSpawnHandler, enemySpawnHandler, hitDetector, aimingIndicatorHandler;

export const initialize = async (app, config, eventBus) => {
  const { enemy, arrow } = config;
  const { collisionRange, spawnTime } = enemy;
  const { shotDeviation, aimThreshold } = arrow;
  
  // Load character spritesheet
  const characterSpritesheet = await loadSpritesheetFromJson (characterUrl, characterJson);

  const enemyPool = new EnemyPool(app, 50, config.enemy.moveSpeed, characterSpritesheet, eventBus);
  const arrowPool = new ArrowPool(app, arrowUrl, 50, config.arrow.arrowSpeed, eventBus);
  
  const player = new Player(characterSpritesheet, enemyPool, eventBus);
  player.x = app.screen.width / 2;
  player.y = app.screen.height * 0.85;
  app.stage.addChild(player);

  aimHandler = new AimHandler(app, config, eventBus);
  arrowSpawnHandler = new ArrowSpawnHandler (app.screen, player, enemyPool, arrowPool, collisionRange, shotDeviation, eventBus);
  enemySpawnHandler = new EnemySpawnHandler (app, enemyPool, spawnTime,  eventBus);
  hitDetector = new HitDetector (app.ticker, enemyPool, arrowPool, collisionRange, eventBus);

  const aimingIndicator = await PIXI.Sprite.from (aimUrl);
  aimingIndicator.anchor.set (0.5);
  aimingIndicatorHandler = new AimingIndicatorHandler(aimingIndicator, aimThreshold, enemyPool, eventBus);
  app.stage.addChild (aimingIndicator);
  
  // Create Texts
  const texts = [
    new GameOverText(app, eventBus),
    new ScoreText(app, eventBus),
    new WatchAdsText(app, eventBus),
    new ShareText (app, eventBus),
    new SkipShareText (app, eventBus)
  ];
  
  texts.forEach (text => app.stage.addChild(text));

  // Handle the start text and credits text 
  const startGroup = new PIXI.Container ();
  const startGameText = new StartGameText(eventBus);
  
  const creditsText = new CreditsText (eventBus);
  creditsText.anchor.set (0.5, 0);
  creditsText.y = 32;
  
  startGameText.addChild (creditsText);
  startGroup.addChild (startGameText);

  eventBus.on('game:start', () => startGroup.visible = false);
  eventBus.on('game:reset', () => startGroup.visible = true);
  startGroup.x = app.screen.width / 2;
  startGroup.y = app.screen.height / 2;
  app.stage.addChild (startGroup);

  const creditsWindow = new CreditsWindow (app, eventBus);
  app.stage.addChild (creditsWindow);
};
