import Text from './text';
import tgService from '../../../services/tgService';

export default class GameOverText extends Text {
  constructor(app, eventBus) {
    super('GAME OVER', 48);
    
    this.x = app.screen.width / 2;
    this.y = app.screen.height / 2;
    this.cursor = "pointer";
    this.interactive = true;
    this.visible = false;

    this.on("pointerup", async () => {
      this.visible = false;

      // If the tg is ready, set the highest score.
      if (tgService.isTgReady()) {
        await tgService.setHighestScore();
        eventBus.emit ('highestScore:set');
      }

      // Otherwise, reset the game
      else
        eventBus.emit ('game:reset');
    });

    eventBus.on ('game:start', () => this.visible = false);
    eventBus.on ('game:over', () => this.visible = true);
  }
}