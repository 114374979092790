import { bowLoadingSfx } from '../sounds';

export default class AimHandler {
  constructor(app, config, eventBus) {
    this.app = app;
    this.aimThreshold = config.arrow.aimThreshold;
    this.eventBus = eventBus;
    this.holdTimer = 0;

    eventBus.on ('game:start', this.onGameStart);
    eventBus.on ('game:over', this.onGameOver);
  }

  onGameStart = () => {
    this.app.view.addEventListener("pointerdown", this.onPointerDown);
    this.app.view.addEventListener("pointerup", this.onPointerUp);
  }

  onGameOver = () => {
    this.app.view.removeEventListener("pointerdown", this.onPointerDown);
    this.app.view.removeEventListener("pointerup", this.onPointerUp);
    this.holdTimer = 0;
  }

  onPointerDown = () => {
    bowLoadingSfx.play({ volume: 1 });
    this.eventBus.emit('aim:start');

    // Add ticker for aiming.
    this.app.ticker.add (this.aimTick)
  }

  onPointerUp = () => {
    if (this.holdTimer === 0)
      return;
    
    // Remove ticker for aiming.
    this.app.ticker.remove (this.aimTick)
    this.shoot ();
  }

  aimTick = (deltaTime) => {
    this.holdTimer += deltaTime;
    this.eventBus.emit('aim:update', this.holdTimer);
  };
  
  shoot = () => {
    console.log (`shoot: ${this.holdTimer}`);
    // aimThreshold is an array [30, 40], check if the holdTimer is within the range.
    const [min, max] = this.aimThreshold;
    const isHit = this.holdTimer >= min && this.holdTimer <= max;
    
    this.eventBus.emit('aim:shoot', isHit);
    this.holdTimer = 0;
  }
}