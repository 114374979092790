import config from "./config.js";
import { initialize as initializeStage } from "./stages/mainStage.js";
import EventBus from "./utils/eventBus.js";
import backgroundImage from './assets/sprites/backgrounds/Bg.png';
import AdController from './components/adController.js';
import tgService from "./services/tgService.js";
import gameService from "./services/gameService.js";

document.addEventListener("DOMContentLoaded", () => {
  window.WebFontConfig = {
    google: {
        families: ['Pixelify Sans'],
    },
    active() {
      /* eslint-enabled */
      startGame();
    }
  };
  
  /* eslint-disable */
  // include the web-font loader script
  (function () {
      const wf = document.createElement('script');
      wf.src = `${
          document.location.protocol === 'https:' ? 'https' : 'http'
      }://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js`;
      wf.type = 'text/javascript';
      wf.async = 'true';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(wf, s);
  })();
});

const startGame = () => {
  try {
    const eventBus = new EventBus();
    tgService.initialize();
    gameService.initialize(eventBus);

    const app = new PIXI.Application({ background: "#000", resizeTo: window });
    document.body.appendChild(app.view);

    // Prevent touch default behavior
    app.view.addEventListener("touchstart", (e) => e.preventDefault());

    const background = PIXI.Sprite.from(backgroundImage);
    background.width = app.screen.width;
    background.height = app.screen.height;
    app.stage.addChild(background);

    // Iniailize the stage with UI components
    initializeStage(app, config, eventBus);
  } 
  // Catch any error and display it on screen to help debugging
  catch (err) {
    // Pop up an alert with the error message
    alert(err.message);
    console.log (err);
  }
};