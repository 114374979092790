import { arrowHitSfx, arrowHitMetalSfx, goblinDeathSfx1, goblinDeathSfx2 } from "./sounds";

export default class HitDetector {
  constructor (ticker, enemyPool, arrowPool, collisionRange, eventBus) {
    this.ticker = ticker;
    this.enemyPool = enemyPool;
    this.arrowPool = arrowPool;
    this.collisionRange = collisionRange;
    this.eventBus = eventBus;

    this.ticker.add (this.detectHit);
  }

  detectHit = () => {
    const enemy = this.enemyPool.getPossibleTarget();
    // No need to check collision if no enemy spawned
    if (!enemy) return;

    // Collision detection
    for (let j = 0; j < this.arrowPool.activeArrows.length; j++) {
      const arrow = this.arrowPool.activeArrows[j];

      if (
          arrow.sprite.x >= enemy.x - this.collisionRange &&
          arrow.sprite.x <= enemy.x + this.collisionRange &&
          arrow.sprite.y <= enemy.y &&
          arrow.targetId === enemy.id
      ) {
          this.arrowPool.recycle(arrow);
          const isEnemyDead = this.enemyPool.dealDamage(enemy);
          if (isEnemyDead){
            arrowHitSfx.play({ volume: 0.5 });
            // Randomly play one of the two goblin death sounds
            Math.random() < 0.5 ? goblinDeathSfx1.play({ volume: 0.5 }) : goblinDeathSfx2.play({ volume: 0.5 });
          }
          else
            arrowHitMetalSfx.play({ volume: 0.5 });

          if (!isEnemyDead) continue;
          this.eventBus.emit ('aim:hit');

          // Quit because one arrow will only hit one enemy
          break;
      }
    }
  }
}