import Text from './text';

export default class StartGameText extends Text {
  constructor(eventBus) {
    super("START", 48);
    this.cursor = "pointer";
    this.interactive = true;

    // Emit event when clicked
    this.on("pointerup", () => {
      eventBus.emit("game:start");
    });
  }
}