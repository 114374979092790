export const loadFromRow = async (url, width, height, length, rowIndex, scale = 1, anchor = 0.5) => {
  const texture = await PIXI.Assets.load(url);
  const frames = [];

  // Create the frames
  for (let j = 0; j < length; j++) {
      frames.push({
          frame: { x: j * width, y: rowIndex * height, w: width, h: height },
          sourceSize: { w: width, h: height },
          spriteSourceSize: { x: 0, y: 0, w: width, h: height },
      });
  }

  // Create the spritesheet
  const spritesheet = new PIXI.Spritesheet(texture, {
      frames,
      animations: {
          animatedSprite: Array.from({ length }, (_, i) => i),
      },
      meta: {
          scale
      },
  });

    // Parse the spritesheet
  await spritesheet.parse();
  
  // Create animated sprite
  const sprite = new PIXI.AnimatedSprite(spritesheet.animations.animatedSprite);
  sprite.anchor.set (anchor);
  return sprite;
};

export const loadSpritesheetFromJson = async (imageUrl, jsonPath) => {
    const texture = await PIXI.Assets.load(imageUrl);

      // Create the spritesheet
    const spritesheet = new PIXI.Spritesheet(texture, jsonPath);

    // Parse the spritesheet
    await spritesheet.parse();

    return spritesheet;
}