import axios, { AxiosError } from "axios";
import gameService from "./gameService";
let _userId = null, _messageId = null;

const isTgReady = () => _userId !== null;
const isScoreSharable = () => _messageId !== null;

const initialize = () => {
  const { Telegram } = window;
  if (!Telegram) return false;

  const {WebApp} = Telegram;
  WebApp.ready();
    
  // Get Message Id OR Inline Message Id from URL
  const searchParams = window.location.search;
  const urlParams = new URLSearchParams(searchParams);
  _messageId = urlParams.get('inline_message_id');

  // Get user ID can only be obtained through WebApp.initDataUnsafe when
  // the game is launched by inline buttons or menu button.
  if (WebApp.initDataUnsafe && WebApp.initDataUnsafe.user)
    _userId = WebApp.initDataUnsafe.user.id;

  // If the game is not launched by above, but inline keyboard, then it's from the url.
  if (!_userId)
    _userId = urlParams.get('user_id');

  // If it's still not available, it's a fatal error for sure.
  if (!_userId)
    throw new Error (`User ID is not available on url: ${window.location.href}`);

  console.log (`User ID: ${_userId}, Message ID: ${_messageId}`);
  return true;
}

const getPlayerInfo = () => {
  return {
    userId: _userId,
    messageId: _messageId
  };
}

/**
 * Share the score via the Telegram Game API
 */
const shareScore = () => {
  if (!isTgReady()) return false;
  window.TelegramGameProxy.shareScore();
  return true;
}

const setHighestScore = async () => {
  if (!isTgReady() || !isScoreSharable()) return false;
  try {
    await axios.post ('https://flexiometric-tg-bot-dab50747a92d.herokuapp.com/api/v1/highestScores', {
      userId: _userId,
      score: gameService.getScore(),
      messageId: _messageId
    });

    return true;
  } catch (error) {
    if (error instanceof AxiosError)
      console.log (`${error.code}: ${error.response?.data ?? error.message}`);
    else
      console.log (`Cannot set the game score: ${error.message}`);

    return false;
  }
}

const getHighestScores = async () => {
  if (!isTgReady()) return [];

  try {
    const response = await axios.get ('https://flexiometric-tg-bot-dab50747a92d.herokuapp.com/api/v1/highestScores', 
      { params: {
        userId: _userId,
        messageId: _messageId
      }});

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError)
      console.log (`${error.code}: ${error.response?.data ?? error.message}`);
    else
      console.log (`Cannot get the highest scores: ${error.message}`);

    return [];
  }
}

export default {
  isTgReady,
  initialize,
  getPlayerInfo,
  shareScore,
  setHighestScore,
  getHighestScores
};