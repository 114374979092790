import Text from './text';

export default class SkipShareText extends Text {
  constructor(app, eventBus) {
    super("SKIP", 24);
    this.cursor = "pointer";
    this.interactive = true;
    this.visible = false;

    this.x = app.screen.width / 2;
    this.y = app.screen.height / 2 + 48;

    eventBus.on ('highestScore:set', () => this.visible = true);
    eventBus.on ('game:reset', () => this.visible = false);

    // Emit event when clicked
    this.on("pointerup", () => eventBus.emit("game:reset"));
  }
}