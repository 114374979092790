import Text from './text';
import tgService from "../../../services/tgService";

export default class ShareText extends Text {
  constructor(app, eventBus) {
    super("SHARE", 24);
    this.cursor = "pointer";
    this.interactive = true;
    this.visible = false;

    this.x = app.screen.width / 2;
    this.y = app.screen.height / 2;

    eventBus.on ('highestScore:set',  () => this.visible = true);
    eventBus.on ('game:reset', () => this.visible = false);

    // Emit event when clicked
    this.on("pointerup", () => {
      tgService.shareScore();
      eventBus.emit("game:reset");
    });
  }
}