export class Arrow
{
    constructor(app, texturePath)
    {
        this.targetId = -1;
        this.sprite = PIXI.Sprite.from(texturePath);
        this.sprite.visible = false;
        this.sprite.scale.set(1);
        this.sprite.anchor.set(0.5, 0.5);
        app.stage.addChild(this.sprite);
    }

    spawn(x, y, targetId)
    {
        this.targetId = targetId;
        this.sprite.x = x;
        this.sprite.y = y;
        this.sprite.visible = true;
    }

    recycle()
    {
        this.targetId = -1;
        this.sprite.visible = false;
    }
}