export class Enemy extends PIXI.Container
{
    static index = 0;
    constructor(characterSpritesheet, moveSpeed, eventBus)
    {
        super();
        this.id = -1;
        this.moveSpeed = moveSpeed;
        this.eventBus = eventBus;

        // State machine
        this.states = {
            WALK: 'walk',
            WALK_HELMET: 'walkHelmet',
            DEAD: 'dead'
        };

        this.currentState = null;
        
        this.stateTransitions = {
            [this.states.WALK_HELMET]: [this.states.WALK],
            [this.states.WALK]: [this.states.DEAD],
            [this.states.DEAD]: []
        };

        this.stateHandlers = {
            [this.states.WALK_HELMET]: this.handleWalkWithHelmet,
            [this.states.WALK]: this.handleWalk,
            [this.states.DEAD]: this.handleDead
        };

        // Sprites
        this.sprites = {
            [this.states.WALK_HELMET]: new PIXI.AnimatedSprite(characterSpritesheet.animations.helmet_monster_walking),
            [this.states.WALK]: new PIXI.AnimatedSprite(characterSpritesheet.animations.monster_walking),
            [this.states.DEAD]: new PIXI.Sprite (characterSpritesheet.textures.monster_dead)
        };

        // Add all animations and sprite to the container.
        for (const sprite of Object.values(this.sprites)) {
            sprite.anchor.set (0.5);
            sprite.visible = false;
            if (sprite instanceof PIXI.AnimatedSprite) {
                sprite.animationSpeed = 0.1;
                sprite.loop = true;
            }
            this.addChild(sprite);
        }

        eventBus.on ('game:start', this.reset);
        eventBus.on ('game:reset', this.reset);
    }

    setCurrentPlayState(playOrStop) {
        if (!this.currentState) return;
        
        const sprite = this.sprites[this.currentState];
        if(!(sprite instanceof PIXI.AnimatedSprite)) return;
        
        sprite.visible = playOrStop;
        if(playOrStop)
            sprite.play();
        else
            sprite.stop();
    }

    reset = () => {
        this.id = -1;
        this.visible = false;
        this.currentState = null;
        this.setCurrentPlayState(false);
        Object.values(this.sprites).forEach (sprite => sprite.visible = false);
    }

    spawn(x, y, initialState)
    {
        this.id = ++Enemy.index;
        this.visible = true;
        this.x = x;
        this.y = y;
        this.changeState(initialState);
    }

    takeDamage()
    {
        switch (this.currentState) {
            case this.states.WALK_HELMET:
                this.changeState(this.states.WALK);
                return false;
            case this.states.WALK:
                this.changeState(this.states.DEAD);
                break;
            case this.states.DEAD:
                break;
        }
        return true;
    }

    update(delta) {
        if (this.id === -1) return;
        this.stateHandlers[this.currentState](delta);
    }

    changeState(newState) {
        if (this.currentState && !this.stateTransitions[this.currentState].includes(newState)) {
            console.error(`Invalid state transition from ${this.currentState} to ${newState}`);
            return;
        }

        if (this.currentState)
            this.sprites[this.currentState].visible = false;
        
        this.sprites[newState].visible = true;
        this.currentState = newState;
        
        this.setCurrentPlayState(true);
    }

    handleWalkWithHelmet = delta => {
        this.y += this.moveSpeed * delta;
    }

    handleWalk = delta => {
        this.y += this.moveSpeed * delta;
    }
    
    handleDead = _ => {
        if (this.id === -1) return;
        this.reset();
        this.eventBus.emit ('enemy:recycle', this);
    }
}