export default {
    frames: {
        "helmet_monster_die.png": {
            frame: { x: 0, y: 0, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "helmet_monster_walking_1.png": {
            frame: { x: 64, y: 0, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "helmet_monster_walking_2.png": {
            frame: { x: 128, y: 0, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "helmet_monster_walking_3.png": {
            frame: { x: 192, y: 0, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "main_char_attack_1.png": {
            frame: { x: 0, y: 64, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "main_char_attack_2.png": {
            frame: { x: 64, y: 64, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "main_char_attack_3.png": {
            frame: { x: 128, y: 64, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "main_char_idle_1.png": {
            frame: { x: 192, y: 64, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "main_char_idle_2.png": {
            frame: { x: 0, y: 128, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "main_char_idle_3.png": {
            frame: { x: 64, y: 128, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "monster_die.png": {
            frame: { x: 128, y: 128, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "monster_walking_1.png": {
            frame: { x: 192, y: 128, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "monster_walking_2.png": {
            frame: { x: 0, y: 192, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
        "monster_walking_3.png": {
            frame: { x: 64, y: 192, w: 64, h: 64 },
            rotated: false,
            trimmed: false,
            spriteSourceSize: { x: 0, y: 0, w: 64, h: 64 },
            sourceSize: { w: 64, h: 64 },
        },
    },
    animations: {
        helmet_monster_walking: [
            "helmet_monster_walking_1.png",
            "helmet_monster_walking_2.png",
            "helmet_monster_walking_3.png",
        ],
        main_char_attack: [
            "main_char_attack_1.png",
            "main_char_attack_2.png",
            "main_char_attack_3.png",
        ],
        main_char_idle: [
            "main_char_idle_1.png",
            "main_char_idle_2.png",
            "main_char_idle_3.png",
        ],
        monster_walking: [
            "monster_walking_1.png",
            "monster_walking_2.png",
            "monster_walking_3.png",
        ],
    },
    meta: {
        app: "https://www.codeandweb.com/texturepacker",
        version: "1.1",
        image: "characters-default.webp",
        format: "RGBA4444",
        size: { w: 256, h: 256 },
        scale: 0.5,
        smartupdate:
            "$TexturePacker:SmartUpdate:4dd2ee60aef8ea95f643bbafeb982e0a:f7761c1d89c607d53a5c9bec7c701214:c0e7f5f5c4050b0a301f5bfedcf9033a$",
    },
};
